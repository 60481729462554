import { EciDxService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ArticleHistoryService {

    constructor(
        private readonly dxService: EciDxService,
    ) { }

    createDataSource(): DataSource {
        return this.dxService.createDataSource('api/article-history');
    }
}
