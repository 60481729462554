import { EciDxService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChangeHistoryService {

    constructor(
        private readonly dxService: EciDxService,
    ) { }

    /**
     * Returns the Change History Log DataSource.
     *
     * @returns dx-data source
     */
    createChangeHistoryDataSource(): DataSource {
        return this.dxService.createDataSource('api/change-histories');
    }

}
