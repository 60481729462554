import { Injectable } from '@angular/core';
import { EciTenantSwitcher } from '@shared-components/shopsys-commons-ui';
import { Observable } from 'rxjs';

interface Country {
    id: string;
    name: string;
}

@Injectable({
     providedIn: 'root',
})
export class CountryService {
    country: Country;
    getCountry: Observable<Country>;

    constructor(private readonly tenantSwitcher: EciTenantSwitcher) {
        this.getCountry = Observable.create(observer => {
            this.tenantSwitcher.getUserTenant().subscribe(tenant => {
                this.country = {
                    id: tenant.selectedTenant,
                    name: this.getCountryName(tenant),
                };
                observer.next(this.country);
            });
        });
    }

    getCountryName(tenant) {
         if (tenant?.availableTenants && Array.isArray(tenant.availableTenants)) {
               const tenantObject = tenant.availableTenants.find(x => x.id === tenant.selectedTenant);
               if (tenantObject?.name && tenantObject.name.length > 0) {
                    return tenantObject.name;
               }
         }
         return tenant.selectedTenant;
    }
}
