import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';

@Injectable()
export class PlantCalculationClusterService {
    constructor( private readonly dxService: EciDxService ) { }

    createDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/plant-calculation-cluster',
            'id',
        );
    }
}
