import { ErrorHandler, Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EciDxQueryResult, EciDxService, EciQueryEncoder } from '@shared-components/shopsys-commons-ui';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { EciDxDataSourceOptions } from '@shared-components/shopsys-commons-ui/lib/shared/dx/dx-data-source-options.model';
import { MasterArticleJob } from '../../../master-article-job/MasterArticleJob';
import LoadOptions from 'devextreme/data/load_options';
import { catchError, map }  from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ZipcodeRange } from './ZipCodeDelivery';

@Injectable()
export class ZipCodeDeliveryService {
    constructor(private readonly httpClient: HttpClient,
                private readonly dxService: EciDxService,
                private readonly errorHandler: ErrorHandler,
    ) {
    }

    createDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/zip-code-delivery',
            'zipCode',
        );
    }

    createEditableDataSource(
        file: File,
        countryId: string,
        additionalOptions: EciDxDataSourceOptions = {},
        headers?: string | { [name: string]: string | string[] },
    ): DataSource {
        const options: EciDxDataSourceOptions = {
            key: 'zipCode',
            load: loadOptions => this.query<ZipcodeRange[]>('api/zip-code-delivery/search-from-file', file, loadOptions, countryId, headers),
            update: (key, value: ZipcodeRange) => this.updateElement<ZipcodeRange>('api/zip-code-delivery', key, value),
            remove: key => this.removeElement('api/zip-code-delivery', key),
        };

        return new DataSource(options);
    }

    deleteIslandDelivery(selectedZipCodes: any, deselectedZipCodes: any,
                         selectAll: any, loadOptionsFilter: any,
                         countryId: string, file: File ): Observable<HttpResponse<object>> {

        const deleteForm: FormData = new FormData();
        if (file != null) {
            deleteForm.append('file', file, file.name);
        }
        deleteForm.append('selectedZipCodes', selectedZipCodes);
        deleteForm.append('deselectedZipCodes', deselectedZipCodes);
        deleteForm.append('selectAll', selectAll);
        deleteForm.append('loadOptionsFilter', loadOptionsFilter);
        deleteForm.append('countryId', countryId);

        return this.httpClient.post('api/zip-code-delivery/delete', deleteForm,
            { observe: 'response' } );
    }

    createZipCodeDeliveryFromFile(countryId: string, file: File ): Observable<HttpResponse<object>> {

        const formData: FormData = new FormData();
        if (file != null) {
            formData.append('file', file, file.name);
        }
        formData.append('countryId', countryId);

        return this.httpClient.post('api/zip-code-delivery/upload', formData,
            { observe: 'response' } );
    }

    query<TData>(
        url: string,
        file: File,
        loadOptions: LoadOptions.LoadOptions,
        countryId: string,
        headers?: string | { [name: string]: string | string[] },
    ): Promise<any> {
        this.convertSearchToFilter(loadOptions);

        let params: HttpParams = new HttpParams({
            encoder: new EciQueryEncoder(),
        });

        Object.keys(loadOptions).forEach(key => {
            if (loadOptions[key] != null) {
                params = params.append(
                    key,
                    JSON.stringify(loadOptions[key]),
                );
            }
        });

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('countryId', countryId);

        return this.httpClient.post<MasterArticleJob[]>(url, formData,
            {
                params,
                headers: new HttpHeaders(headers),
                observe: 'response', responseType: 'json',
            })
            .pipe(
                map(response => {
                    const totalCount = Number.parseInt(
                        response.headers.get('X-Total-Count'),
                    );
                    const data = response.body;
                    return { totalCount, data } as EciDxQueryResult<MasterArticleJob>;
                }),
                catchError(error => {
                    this.errorHandler.handleError(error);

                    return of(this.buildEmptyEciDxQueryResult());
                }),
            ).toPromise();
    }

    private convertSearchToFilter(loadOptions: LoadOptions.LoadOptions) {
        const { searchExpr, searchOperation, searchValue } = loadOptions;

        // If one of the required search parameters is not set, skip processing them at all
        if (
            searchExpr == null ||
            searchOperation == null ||
            searchValue == null
        ) {
            return;
        }

        // Build filters based on given search expressions and replace existing filters
        loadOptions.filter = [];
        (Array.isArray(searchExpr) ? searchExpr : [searchExpr]).forEach(
            (expr, index) => {
                if (index !== 0) {
                    loadOptions.filter.push('or');
                }

                loadOptions.filter.push([expr, searchOperation, searchValue]);
            },
        );
    }

    private buildEmptyEciDxQueryResult(): EciDxQueryResult<any> {
        return { data: [], totalCount: 0 };
    }

    private updateElement<T = any>(
        url: string,
        key: string,
        data: T,
    ): Promise<T> {
        return this.httpClient.put<T>(`${url}/${key}`, data)
            .pipe(catchError(error => {
                this.errorHandler.handleError(error);

                return throwError(error);
            }))
            .toPromise();
    }

    private removeElement(url: string, key: string): Promise<number> {
        return this.httpClient.delete<number>(`${url}/${key}`)
            .pipe(catchError(error => {
                this.errorHandler.handleError(error);

                return throwError(error);
            }))
            .toPromise();
    }
}
