import { EciDxService, EciLocaleService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { downloadBlob } from 'src/helpers/download';
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CalculationLogService {

    constructor(
        private readonly dxService: EciDxService,
        private readonly httpClient: HttpClient,
        private readonly locale: EciLocaleService,
    ) { }

    /**
     * Returns the CalculationLog DataSource.
     *
     * @returns dx-data source
     */
    createLogDataSource(): DataSource {
        return this.dxService.createDataSource('api/calculation-logs');
    }

    /**
     * Downloads the CalculationLog as CSV
     *
     */
    downloadCsv(country: string): void {
        this.httpClient.get('/api/calculation-logs/csv', { responseType: 'blob' })
            .subscribe((value: Blob) => {
                downloadBlob(value, `log_${country}_${formatDate(new Date(), 'yyyy-MM-dd_hh-mm-ss',
                    this.locale.getCurrentLocale())}.csv`);
        });
    }

}
