import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { CalculationLogService } from './calculation-log.service';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';
import { DataGridUtilService } from '../service/DataGridUtilService';

@Component({
    selector: 'vltc-calculation-log',
    templateUrl: './calculation-log.component.html',
    styleUrls: ['./calculation-log.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CalculationLogComponent implements OnInit {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;

    constructor(
        private readonly calculationLogService: CalculationLogService,
        public translateService: EciTenantAwareLanguageService,
        private readonly countryService: CountryService,
        public dataGridUtilService: DataGridUtilService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c.name);
    }

    ngOnInit() {
        this.dataSource = this.calculationLogService.createLogDataSource();
    }

    downloadCsv() {
        this.calculationLogService.downloadCsv(this.country);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'fa fa-download',
                text: this.translateService.get(
                    'app.calculationLog.toolbar.downloadCSV',
                ),
                onClick: this.downloadCsv.bind(this),
            },
        });
    }
}
