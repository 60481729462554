import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { PlantCalculationClusterService } from './plant-calculation-cluster.service';
import notify from 'devextreme/ui/notify';
import { CountryService } from 'src/helpers/CountryService';

@Component({
    selector: 'vltc-plant-calculation-cluster',
    templateUrl: './plant-calculation-cluster.component.html',
    styleUrls:['plant-calculation-cluster.component.scss'],
})
export class PlantCalculationClusterComponent {
    @ViewChild(DxDataGridComponent)
    dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;
    isSelectAllEnabled = false;
    selectedArticlesMap = new Map<number, string[]>();
    deselectedArticlesMap = new Map<number, string[]>();
    selectedRows: string[] = [];

    constructor(
        private readonly plantCalculationClusterService: PlantCalculationClusterService,
        public translateService: EciTenantAwareLanguageService,
        private readonly countryService: CountryService,
    ) {
        this.dataSource = this.plantCalculationClusterService.createDataSource();
        this.countryService.getCountry.subscribe(c => this.country = c.id);
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    getTotalRowCount(): number {
        let totalRows: number;
        if (this.isSelectAllEnabled) {
            totalRows = this.dataGrid.instance.totalCount() - this.convertMapValuesToArray(this.deselectedArticlesMap).length;
        } else {
            totalRows = this.dataGrid.instance.getSelectedRowKeys().length;
        }
        return totalRows;
    }

    convertMapValuesToArray(map: Map<number, string[]>): string[] {
        const result: string[] = [];
        map.forEach((value: string[], key: number) => {
            value.forEach(value1 => result.push(value1));
        });
        return result;
    }

    onEditorPreparing(event) {
        if (event.parentType === 'headerRow' && event.command === 'select') {
            event.editorElement.remove();
        }

        if (event.parentType === 'filterRow' && event.dataField === 'itemGroupCategory') {
            event.cancel = true;
        }
    }

    onNewEntry(event) {
        event.data.active = true;
    }
    onSelectionChanged() {
        this.selectedArticlesMap.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => r.isSelected).map(m => m.key));
        this.deselectedArticlesMap.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => !r.isSelected).map(m => m.key));
    }

    onOptionChanged(e) {
        if (e.fullName === 'paging.pageIndex') {
            console.log('onOptionChanged');
        }
    }

    onRowUpdating(options) {
        options.newData = { ...options.oldData, ...options.newData };
    }
}
