import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { ZipCodeDeliveryService } from './zip-code-delivery.service';
import notify from 'devextreme/ui/notify';
import { CountryService } from 'src/helpers/CountryService';
import FileUploader from 'devextreme/ui/file_uploader';
import { confirm } from 'devextreme/ui/dialog';
import DxButton from 'devextreme/ui/button';

@Component({
    selector: 'vltc-zip-code-delivery',
    templateUrl: './zip-code-delivery.component.html',
    styleUrls:['zip-code-delivery.component.scss'],
})
export class ZipCodeDeliveryComponent {
    @ViewChild(DxDataGridComponent)
    dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;
    fileToUpload: File = null;
    fileName: any[] = [];
    isSelectAllEnabled = false;
    selectedArticlesMap = new Map<number, string[]>();
    deselectedArticlesMap = new Map<number, string[]>();
    selectedRows: string[] = [];

    constructor(
        private readonly zipCodeDeliveryService: ZipCodeDeliveryService,
        public translateService: EciTenantAwareLanguageService,
        private readonly countryService: CountryService,
    ) {
        this.dataSource = this.zipCodeDeliveryService.createDataSource();
        this.countryService.getCountry.subscribe(c => this.country = c.id);
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    uploadFile(event) {
        const files = event.value;
        if (files) {
            this.fileToUpload = files[0];
            console.log('FileUpload -> files ', this.fileToUpload);
        }
    }

    changeDatasource() {
        try {
            this.dataSource = null;
            this.dataSource = this.zipCodeDeliveryService.createEditableDataSource(this.fileToUpload, this.country);
            this.dataGrid.instance.endCustomLoading();
            this.dataGrid.instance.repaint();

        } catch (e) {
            console.error('Error in changing data source ' + e);
        }
    }

    resetFileUploader() {
        this.getFileUploader().reset();
        this.dataSource = null;
        this.dataSource = this.zipCodeDeliveryService.createDataSource();
    }

    getFileUploader(): FileUploader {
        const fileUploaderElement = document.getElementById('fileUploaderId');
        return FileUploader.getInstance(fileUploaderElement) as FileUploader;
    }

    getTotalRowCount(): number {
        let totalRows: number;
        if (this.isSelectAllEnabled) {
            totalRows = this.dataGrid.instance.totalCount() - this.convertMapValuesToArray(this.deselectedArticlesMap).length;
        } else {
            totalRows = this.dataGrid.instance.getSelectedRowKeys().length;
        }
        return totalRows;
    }

    convertMapValuesToArray(map: Map<number, string[]>): string[] {
        const result: string[] = [];
        map.forEach((value: string[], key: number) => {
            value.forEach(value1 => result.push(value1));
        });
        return result;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
                location: 'before',
                template: 'totalGroupCount',
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    width: 136,
                    text: this.isSelectAllEnabled ? this.translateService.get('zip-code-delivery.toolbar.deselectAll')
                        : this.translateService.get('zip-code-delivery.toolbar.selectAll'),
                    onClick: this.selectAllArticles.bind(this),
                    elementAttr: {
                        id: 'selectAllButtonId',
                    },
                },

            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    width: 90,
                    text: this.translateService.get('app.masterArticleJob.toolbar.delete'),
                    type: 'success',
                    onClick: this.deleteZipCodeDelivery.bind(this),
                },
            });
    }

    selectAllArticles(e) {
        if (this.isSelectAllEnabled === false) {
            this.selectedArticlesMap.clear();
            this.deselectedArticlesMap.clear();
            this.selectAllVisibleRows();
            this.isSelectAllEnabled = true;
            e.component.option({
                text: this.translateService.get('zip-code-delivery.toolbar.deselectAll'),
            });

        } else {
            this.isSelectAllEnabled = false;
            this.selectedArticlesMap.clear();
            this.deselectedArticlesMap.clear();
            this.dataGrid.instance.clearSelection();
            e.component.option({
                text: this.translateService.get('zip-code-delivery.toolbar.selectAll'),
            });
        }
    }

    deleteZipCodeDelivery() {
        if (Array.isArray(this.selectedRows) && this.selectedRows.length > 0) {
            const result = confirm('<i>' +
                this.translateService.get('app.deliveryProduct.confirmationDialog.confirmationMessage') +
                ' ' + this.getTotalRowCount() + ' ' +
                this.translateService.get('app.deliveryProduct.confirmationDialog.articles') + ' ?</i>',
                this.translateService.get('app.deliveryProduct.confirmationDialog.confirmChanges'));
            result.then(dialogResult => {
                if (dialogResult) {
                    this.dataGrid.instance.beginCustomLoading(this.translateService.get('app.deliveryProduct.confirmationDialog.savingArticles'));
                    this.zipCodeDeliveryService.deleteIslandDelivery(this.convertMapValuesToArray(this.selectedArticlesMap),
                        this.convertMapValuesToArray(this.deselectedArticlesMap), this.isSelectAllEnabled,
                        typeof this.dataGrid.instance.getCombinedFilter() !== 'undefined' ?
                            JSON.stringify(this.dataGrid.instance.getCombinedFilter()) : '[]',
                        this.country, this.fileToUpload)
                        .subscribe(response => {
                            notify(response.body + ' ' +
                                this.translateService.get('app.deliveryProduct.confirmationDialog.articlesWereSaved'),
                                'success', 1000);
                            this.updateElementsAfterUpdate();
                        });
                }
            });
        }
    }

    selectAllVisibleRows() {
        const visibleRows = this.dataGrid.instance.getVisibleRows().map(r => r.key);
        this.dataGrid.instance.selectRows(visibleRows, true).then(r => {});
    }

    updateElementsAfterUpdate() {
        this.isSelectAllEnabled = false;
        this.selectedRows.length = 0;
        this.selectedArticlesMap.clear();
        this.deselectedArticlesMap.clear();
        this.dataGrid.instance.clearSelection();
        this.dataGrid.instance.refresh().then(r => {});
        this.dataGrid.instance.endCustomLoading();
        // there's no two way binding for toolbar elements
        const buttonElement = document.getElementById('selectAllButtonId');
        DxButton.getInstance(buttonElement).option('text', this.translateService.get('zip-code-delivery.toolbar.selectAll'));
    }

    onEditorPreparing(event) {
        if (event.parentType === 'headerRow' && event.command === 'select') {
            event.editorElement.remove();
        }

        if (event.parentType === 'filterRow' && event.dataField === 'itemGroupCategory') {
            event.cancel = true;
        }
    }

    onNewEntry(event) {
        event.data.active = true;
    }

    onContentReady() {
        if (this.isSelectAllEnabled === true) {
            const selectedArticlesPerPage = this.selectedArticlesMap.get(this.dataGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.dataGrid.instance.selectRows(selectedArticlesPerPage, true).then(r => {});
            } else {
                this.selectAllVisibleRows();
            }
        }
        if (this.isSelectAllEnabled === false) {
            const selectedArticlesPerPage = this.selectedArticlesMap.get(this.dataGrid.instance.pageIndex());
            if (selectedArticlesPerPage !== undefined && selectedArticlesPerPage.length > 0) {
                this.dataGrid.instance.selectRows(selectedArticlesPerPage, true).then(r => {
                });
            }
        }
    }

    onSelectionChanged() {
        this.selectedArticlesMap.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => r.isSelected).map(m => m.key));
        this.deselectedArticlesMap.set(this.dataGrid.instance.pageIndex(),
            this.dataGrid.instance.getVisibleRows().filter(r => !r.isSelected).map(m => m.key));
    }

    onOptionChanged(e) {
        if (e.fullName === 'paging.pageIndex') {
            console.log('onOptionChanged');
        }
    }

    createZipCodeDeliveryFromFile() {
        const result = confirm('<i>' +
            this.translateService.get('zip-code-delivery.confirmationDialog.confirmationMessage')
            + '</i>',
            this.translateService.get('zip-code-delivery.confirmationDialog.confirmChanges'));
        result.then(dialogResult => {
            if (dialogResult) {
                this.zipCodeDeliveryService.createZipCodeDeliveryFromFile(this.country, this.fileToUpload)
                    .subscribe(response => {
                        this.dataGrid.instance.refresh().then(r => {});
                        notify(response.body + ' ' +
                            this.translateService
                                .get('app.deliveryProduct.confirmationDialog.articlesWereSaved'),
                            'success', 1000);
                    });
            }
        });
    }
}
