import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// @ts-ignore
@Injectable()
export class MaintenanceModeInterceptor implements HttpInterceptor {

    constructor(
        private readonly router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 503 && error.headers.has('X-Extended-Status')
                        && error.headers.get('X-Extended-Status') === '503.1') {
                        console.warn('Maintenance mode detected!');
                        this.router.navigate(['/', 'maintenance']);
                    }

                    throw error;
                }),
            );
    }

}
