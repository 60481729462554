import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { EciLanguageHelper, EciNavbarComponent, EciPrincipalService } from '@shared-components/shopsys-commons-ui';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './main.component.html',
})
export class VltcMainComponent implements OnInit {

    @ViewChild(EciNavbarComponent) navbar: EciNavbarComponent;

    isHomepage: boolean;

    constructor(
        public readonly principalService: EciPrincipalService,
        private readonly languageHelper: EciLanguageHelper,
        private readonly router: Router,
    ) { }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.languageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));

                this.isHomepage = this.router.url === '/';
            }
        });
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'VLTC';

        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }

        return title;
    }

}
