import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DataGridUtilService {

    calculateFilterExpression(filterValue: any, selectedFilterOperation: any) {
        const column = this as any;
        let filterExpression: any;
        let startDate: any;
        let endDate: any;

        if (Array.isArray(filterValue)) {
            if (filterValue[0] != null) {
                const date = filterValue[0];
                startDate = getDateUTC(date);
            }
            if (filterValue[1] != null) {
                const date = filterValue[1];
                endDate = getDateUTC(date);
            }
        } else {
            startDate = getDateUTC(filterValue);
        }

        if (selectedFilterOperation === 'between') {
            filterExpression = filterBetween(filterValue, column, startDate, endDate, selectedFilterOperation);
            return filterExpression;
        }

        if (selectedFilterOperation === '<>') {
            const endTime = new Date(startDate.getTime());
            endTime.setMinutes(endTime.getMinutes() + 1);
            filterExpression = [
                [column.dataField, '<', startDate.toISOString()],
                'or',
                [column.dataField, '>=', endTime.toISOString()],
            ];
            return filterExpression;
        }

        if (selectedFilterOperation === '=') {
            const endTime = new Date(startDate.getTime());
            endTime.setMinutes(endTime.getMinutes() + 1);
            filterExpression = [
                [column.dataField, '<', endTime.toISOString()],
                'and',
                [column.dataField, '>=', startDate.toISOString()],
            ];
            return filterExpression;
        }

        if (selectedFilterOperation === '>' || selectedFilterOperation === '<'
            || selectedFilterOperation === '>=' || selectedFilterOperation === '<=') {
            filterExpression = [column.dataField, selectedFilterOperation, startDate.toISOString()];
            return filterExpression;
        }
        return column.defaultCalculateFilterExpression(...arguments);
    }
}

function getDateUTC(date: any) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

function filterBetween(filterValue: any, column: any, startDate: any, endDate: any, selectedFilterOperation: any) {
    if (Array.isArray(filterValue)) {
        if (filterValue[0] != null && filterValue[1] == null) {
            return [column.dataField, '>', startDate.toISOString()];
        } else if (filterValue[0] == null && filterValue[1] != null) {
            return [column.dataField, '<', endDate.toISOString()];
        } else {
            return [
                [column.dataField, '>', startDate.toISOString()],
                'and',
                [column.dataField, '<', endDate.toISOString()],
            ];
        }
    } else {
        return [column.dataField, selectedFilterOperation, startDate.toISOString()];
    }
}
